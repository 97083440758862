import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import EmailInputForm from 'components/shared/email-input-form';
import Heading from 'components/shared/heading';
import { gtagBookDemoSubmissionSuccessEvent } from 'constants/constants';

import illustration from './images/illustration.svg';
import lineCenterLeft from './images/line-center-left.svg';
import lineCenterRight from './images/line-center-right.svg';
import lineTopLeft from './images/line-top-left.svg';
import lineTopRight from './images/line-top-right.svg';

const FORM_ID = 1;

// Title
const titleClassNames = classNames(
  /* base */ 'relative uppercase mx-auto text-center max-w-[505px] !leading-none z-10',
  /* 2xl-size */ '2xl:max-w-[785px]',
  /* lg-size */ 'lg:max-w-[675px]'
);

const Hero = ({ title, videoUrl, formTitle, buttonText, buttonUrl, textAboveForm }) => {
  const iframeSrc = `https://www.loom.com/embed/${videoUrl}`;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [IframeRef, setIframeRef] = useState(false);

  useEffect(() => {
    if (!IframeRef) return;
    if (IframeRef) {
      setIsIframeReady(true);
    }
  }, [IframeRef, setIsIframeReady]);

  return (
    <section className="header-offset">
      <Container className="px-0">
        <div className="relative p-8 px-4 pb-0 2xl:px-32 xl:px-18 lg:px-10 md:pt-20 md:px-14 overflow-hidden ">
          <div className="absolute inset-0 overflow-hidden bg-secondary-4 h-[calc(100%-100px)] xl:rounded-5xl md:rounded-4xl -z-10" />

          <Heading className={titleClassNames} tag="h1" size="lg">
            {title}
          </Heading>

          <div className="relative flex flex-col justify-center mt-14">
            <div className="relative">
              <div className="relative w-full flex-shrink-0 after:block after:pb-[56.42633228840126%] 2xl:pb-0 h-full lg:max-w-[968px] mx-auto z-10">
                <iframe
                  className={classNames(
                    'absolute top-0 left-0 w-full h-full transition-all duration-500',
                    {
                      'opacity-100': isIframeReady,
                      'opacity-0': !isIframeReady,
                    }
                  )}
                  src={iframeSrc}
                  frameBorder="0"
                  ref={setIframeRef}
                  allowFullScreen
                />
              </div>

              <img
                className="hidden xl:block absolute top-1/2 -left-32 translate-y-[calc(-50%-65px)]"
                src={lineCenterLeft}
                height={130}
                width={338}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="hidden xl:block absolute top-1/2 -right-32 translate-y-[calc(-50%-65px)]"
                src={lineCenterRight}
                height={130}
                width={338}
                alt=""
                loading="lazy"
                aria-hidden
              />
            </div>

            <div className="relative mt-14 bg-secondary-1 rounded-4xl grid p-10 items-center overflow-hidden lg:grid-cols-2 grid-cols-1 gap-x-3.5">
              <h3 className="text-2xl md:text-3xl 2xl:text-4xl xl:max-w-[360px] 2xl:max-w-[432px] font-extrabold text-center lg:text-left with-link-primary text-white !leading-[1.12]">
                {formTitle}
              </h3>
              <div className="relative mt-5 lg:mt-0">
                <div
                  className="text-xs xl:text-sm text-gray-5 text-center lg:text-left with-link-primary"
                  dangerouslySetInnerHTML={{ __html: textAboveForm }}
                />
                {!buttonUrl && (
                  <>
                    <EmailInputForm
                      className="book-demo-form w-full mt-3"
                      formName="get-free-demo"
                      buttonText={buttonText}
                      size="xs"
                      formId={FORM_ID}
                      gtagSuccessEvent={gtagBookDemoSubmissionSuccessEvent}
                    />
                    <img
                      className="hidden absolute left-[-129px] bottom-2 xl:block"
                      src={illustration}
                      height={249}
                      width={129}
                      alt=""
                      loading="lazy"
                      aria-hidden
                    />
                  </>
                )}
                {buttonUrl && (
                  <div className="text-center lg:text-left">
                    <Button
                      className="justify-center w-full sm:w-auto sm:px-12 sm:py-6 xl:px-12 "
                      to={buttonUrl}
                      size="md"
                      target="_blank"
                    >
                      {buttonText}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <img
            className="hidden md:block absolute top-0 left-0 max-w-[110px] lg:max-w-[130px] xl:max-w-full"
            src={lineTopLeft}
            height={163}
            width={163}
            alt=""
            loading="lazy"
            aria-hidden
          />
          <img
            className="hidden md:block absolute top-0 right-0 max-w-[110px] lg:max-w-[130px] xl:max-w-full"
            src={lineTopRight}
            height={163}
            width={163}
            alt=""
            loading="lazy"
            aria-hidden
          />
        </div>
      </Container>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string,
  textAboveForm: PropTypes.string.isRequired,
};

Hero.defaultProps = {
  buttonUrl: '',
};

export default Hero;
