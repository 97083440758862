import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import IconCheck from './images/check.inline.svg';
import LogoIcon from './images/logo.inline.svg';

const Tools = ({ text, items }) => (
  <section className="max-w-[1220px] mx-auto mt-24 md:mt-28 lg:mt-32 mb-24 md:mb-28">
    <Container>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center">
        <Heading
          className="mark-color-primary-1 lg:max-w-[445px] xl:max-w-[585px] 2xl:max-w-[688px]"
          tag="h2"
          size="lg"
        >
          <LogoIcon className="-mt-2.5 mr-2 inline-flex max-w-[30px] 2xl:max-w-[40px] h-auto" />
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Heading>

        <ul className="mt-8 lg:ml-14 lg:mt-0 grid md:grid-cols-2 gap-y-8 md:gap-x-8 lg:block">
          {items.map(({ text }, index) => (
            <li
              className="flex lg:items-center text-lg sm:text-xl 2xl:text-xxl lg:mb-3.5 2xl:mb-5 last:mb-0 font-semibold"
              key={index}
            >
              <IconCheck />
              <span className="ml-3.5">{text}</span>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
);

Tools.propTypes = {
  text: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Tools;
