import { graphql } from 'gatsby';
import React from 'react';

import Hero from 'components/pages/book-a-demo/hero';
import Tools from 'components/pages/book-a-demo/tools';
import MainLayout from 'layouts/main';

const BookADemo = ({
  data: {
    wpPage: {
      seo,
      acf: { heroBookADemo, toolsBookADemo },
    },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <Hero {...heroBookADemo} />
    <Tools {...toolsBookADemo} />
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      url: uri
      acf {
        heroBookADemo {
          title
          videoUrl
          formTitle
          buttonText
          buttonUrl
          textAboveForm
        }
        toolsBookADemo {
          text
          items {
            text
          }
        }
      }
      ...wpPageSeo
    }
  }
`;

export default BookADemo;
